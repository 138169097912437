<template>
  <div
    :style="slidePosition"
    ref="slide1"
    class="d-flex w-100 h-100 align-items-center justify-content-center z-2 slide-1"
  >
    <h1 class="text-warning mb-0" ref="slide1">
      <span class="slide1-fade-position d-block font-vw-8 font-w-600 ">
        CELEBRATING A
      </span>
      <span class="slide1-fade-position d-block font-vw-8 font-w-600">
        COMMUNITY OF
      </span>
      <span class="slide1-fade-position d-block font-vw-8 font-w-600">
        INNOVATORS
      </span>
    </h1>
  </div>
</template>

<script>
import anime from "animejs"

import { normalize } from "@/utils/ranges"
import { slidePositionPlain } from "@/utils/slidePositioning"

export default {
  props: ["globalUnits", "slideIndex"],
  methods: {
    cutWords() {
      let textWrapper = document.querySelectorAll(".slide1-fade-position")

      //console.log("WRAPPERS", textWrapper.NodeList, textWrapper)

      for (let wrapper of textWrapper) {
        wrapper.innerHTML = wrapper.textContent.replace(
          /\S/g,
          "<span class='letter d-inline-block' style='opacity:0;' >$&</span>"
        )
      }
    },
    slide1(percentage) {
      let animation = anime
        .timeline({ loop: false, autoplay: false, endDelay: 0 })
        .add({
          targets: ".slide1-fade-position .letter",
          translateX: [40, 0],
          opacity: [0, 1],
          easing: "easeOutExpo",
          duration: 1000,
          delay: (el, i) => 4000 + 100 * i
        })
      var progress =
        percentage <= 0.5 ? percentage * 2 : normalize(percentage, 0.5, 1, 1, 0)
      animation.seek(animation.duration * progress)
    }
  },
  mounted() {
    this.cutWords()
    /* anime({
      targets: ".slide1-fade-position .letter",
      translateX: [40, 0],
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 1000,
      delay: (el, i) => 300 + 60 * i
    }) */
  },
  computed: {
    slidePosition() {
      //console.log("SLIDES INDEX", this.slideIndex)
      return slidePositionPlain(this.globalUnits, this.slideIndex)
    },
    innerPosition() {
      return (
        (this.globalUnits - this.slideIndex.start) /
        (this.slideIndex.end - this.slideIndex.start)
      )
    }
  },
  watch: {
    innerPosition() {
      //console.log("changed inner position", this.innerPosition)
      this.slide1(this.innerPosition)
    }
  }
}
</script>

<style></style>
